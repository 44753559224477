@use "./vars" as *;
@use "./mixin" as *;
@use "./helpers" as *;
@import "~bulma";
@import "./react-phone-input";
@import "./react-toastify";
@import "./modal-helpers";
@import "./react-datepicker";
@import "./react-tabs";
@import "../assets/icon-fonts/style.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  line-height: 150%;
  outline: none;
}

html {
  overflow: auto;
}

html,
body {
  background: $color-primary-background;
  color: $color-text;

  font: {
    family: $font-primary;
    size: $color-text-size;
  }
}

html.is-clipped,
html.is-clipped .navbar,
.is-clipped .modal {
  padding-right: 10px;
}

a:focus {
  text-decoration: underline;
}

h1 {
  color: $color-header;

  font: {
    family: $font-primary;
    weight: $font-weight-bold;
  }

  transition: $default-transition-med;
}

h2,
h3,
h4 {
  white-space: pre-wrap;
  color: $color-header;

  font: {
    family: $font-primary;
    weight: $font-weight-regular;
  }

  transition: $default-transition-med;
}

h1 {
  font-size: 48px;

  @include mobileScreen {
    font-size: 36px;
  }
}

h2 {
  font-size: 36px;

  @include mobileScreen {
    font-size: 24px;
  }
}

h3 {
  font-size: 24px;

  @include mobileScreen {
    font-size: 20px;
  }
}

h4 {
  font-size: 20px;

  @include mobileScreen {
    font-size: 18px;
  }
}

h5 {
  font-size: 14px;
  font-weight: 500;
  color: $color-black-500;
}

a {
  font-family: $font-primary;
  color: #4684e7;
  font-size: 14px;
  line-height: 150%;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color-placeholder;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-placeholder;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color-placeholder;
  }
}

textarea,
input,
select,
button {
  font-family: $font-primary;
  color: $color-text;
  font-size: 14px;
  line-height: 150%;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color-placeholder;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-placeholder;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color-placeholder;
  }
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Microsoft Edge */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

label {
  color: $color-header;
  margin-bottom: 4px;
  display: block;

  font: {
    size: 14px;
    weight: $font-weight-medium;
  }
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 8px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #9e9e9e70;
  border-radius: 8px;
}

.title-med {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.button-med {
  border: solid 1px #4684e7;
  border-radius: 5px;
  background: white;
  padding: 4px;
}

.card__body {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(146, 146, 146, 0.1);
  border-radius: 16px;
}

.link__color--primary {
  cursor: pointer;
  user-select: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  text-decoration-line: underline;

  /* Primary/500 (Main) */

  color: #4684e7;
}

.heading__h3 {
  //styleName: Heading/H4;
  /* Heading/H3 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  text-align: center;
  letter-spacing: 0.0025em;

  /* Text/Default */
  color: #080707;
}

.heading__h4 {
  //styleName: Heading/H4;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  color: #2d2f31;
}

.heading__h5 {
  //styleName: Heading/H5;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.heading__h6 {
  //styleName: Heading/H6;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: "#18181B";
}

.body__medium {
  //styleName: Body/Medium;
  // margin-top: 5px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #6d7176;
}

.body__small {
  //styleName: Body/Small;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #71717a;
}

.body__large {
  //styleName: Body/Large;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
}

.body__small-black {
  //styleName: Body/Small;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #18181b;
}

.button__small-12 {
  //styleName: Button/Small 12;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.black {
  color: black !important;
}

.medium__14 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
