@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;


.cmp-sidebar {
  &.pro-sidebar {
    z-index: 120;
    position: fixed;
    top: 0;
    left: 0;
    width: $sidebar-width;
    transition: 0.4s;
    animation: jumpRight 0.6s;

    & .cmp-sidebar__subMenu {
      & .pro-inner-list-item {
        padding-left: 0px !important;

        & ul {
          padding-top: 0 !important;
          padding-bottom: 0 !important;

          & li {
            // padding-top: 8px;
            // padding-bottom: 8px;
            padding-left: 24px;

            & .pro-inner-item {
              &::before {
                display: none !important;
              }
            }

            &.active {
              background-color: $color-primary;
            }
          }
        }
      }

      .pro-icon-wrapper {
        display: inline-block !important;

        // padding-top: 0 !important;
        // padding-bottom: 0 !important;
      }

      & .active {
        background-color: $color-primary;
      }
    }

    &.collapsed {
      & .popper-inner {
        padding-left: 0 !important;
        margin-left: 4px;
      }
    }

    & li {
      &.active {
        background-color: $color-primary;
      }
    }
  }

  &__header {
    padding: 10px 16px;
    text-align: center;

    font-weight: 600;
    font-size: 18px;
    color: white;

    @include mobileScreen {
      padding: 1px 16px;
    }
  }

  &__footer {
    padding: 8px;
    text-align: left;
    font-size: 12px;
    cursor: pointer;

    @include hover {
      filter: brightness(130%);
    }

    &__image {
      margin: 16px auto;
      align-items: center;
      color: white;
    }
  }

  &__image {
    object-fit: contain;

    &--header {
      max-width: 190px;
      max-height: 50px;
      margin-top: 4.7px;
    }

    &--footer {
      height: 40px;
    }
  }

  @keyframes jumpRight {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: none;
    }
  }
}

.custom-dot-menu {
  .ps-sidebar-container {
    overflow-x: visible !important;
    overflow-y: visible !important;
    box-shadow: 0px 4px 20px rgba(146, 146, 146, 0.2);
  }

  .toggle-dt:hover {
    background-color: #EDEFF1;
  }
}

nav.custom-v-menu {
  ul li.ps-open {
    background-color: rgba(251, 252, 253, 1);
  }

  li {
    ul {
      padding: 10px;
    }

    border-radius: 8px;
  }
}



.custome-v-submenu {
  position: relative;

  .ps-active::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 24px;
    left: 0px;
    border-radius: 0px 4px 4px 0px;
    background-color: #4684E7;

  }


}

.cus-sOut-link {
  position: relative;
  padding-top: 20px;

}

.cus-sOut-link::before {

  position: absolute;
  content: "";
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  border-top: 1px solid #CFD4D9;

}

.ps-collapsed {
  .ps-submenu-expand-icon {
    display: none
  }

  .ps-menu-icon {
    min-width: 60px
  }

}
.css-e9e397>.ps-menu-button::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 24px;
  left: 0px;
  border-radius: 0px 4px 4px 0px;
  background-color: #4684E7;
}
