@use "~src/scss/vars" as *;
@use "~src/scss/mixin" as *;
.date-range-picker {
  .react-datepicker-popper {
    position: fixed !important;
    top: auto !important;
    left: auto !important;
    // transform: none !important;
  }
  .react-datepicker__day--in-range {
    background: #e0f1ff !important;
    border: 1px solid #e0f1ff !important;
  }
}
// .react-datepicker__close-icon {
//   top: 25%;
//   right: 7px;
//   width: 16px;
//   height: 16px !important;
//   position: absolute;
// }
// .react-datepicker__close-icon::after {
//   right: 0 !important;
//   top: 0;
//   margin: 0;
// }
.placement-datepicker {
  .react-datepicker-popper {
    position: fixed !important;
    top: auto !important;
    left: auto !important;
    transform: none !important;
  }
}
