.content-search {
  width: 100%;
  position: absolute;
  z-index: 999;
  top: 45px;
  left: 0;
  padding-bottom: 20px;
  max-height: 600px;
  overflow-y: scroll;
  box-shadow: 0px 4px 20px rgba(146, 146, 146, 0.68);

  .content-data {
    .title {
      padding: 10px 20px 0 20px;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 15px;
      color: #6d7176;
    }

    .list {
      .card-search {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          background-color: #eefafe;
          border-radius: 8px;
        }
        .content-des {
          margin: 0 10px;
          .des {
            font-size: 10px;
            font-weight: 400;
            color: #6d7176;
          }
        }
      }
    }
  }

  .content-people {
    .title {
      padding: 10px 20px 0 20px;
      font-size: 12px;
      font-weight: 600;
      color: #6d7176;
    }
    .content {
      .people-item {
        display: flex;
        padding: 7px 20px;
        cursor: pointer;
        &:hover {
          background-color: #eefafe;
          border-radius: 8px;
        }
      }
    }
  }
}
