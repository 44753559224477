.notification-wrapper {
  margin-left: 10px;
  .notification-button {
    position: relative;
    display: flex;
    .dot {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 8px;
      height: 8px;
      background-color: #ee2d2d;
      border-radius: 50%;
      display: inline-block;
    }
  }

  .notification-list {
    width: 400px;
    max-height: 700px;
    border-radius: 8px;
    position: absolute;
    z-index: 999;
    top: 50px;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0px 4px 20px rgb(146 146 146 / 68%);

    .title {
      display: flex;
      justify-content: space-between;
      padding: 12px 24px;
      background-color: #f8f8f9;
      border-bottom: 1px solid #edeff1;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      margin-bottom: 0;
    }

    .read {
      display: flex;
      justify-content: space-between;
      padding: 12px 24px;

      .toggle-read {
        display: flex;
        align-items: center;
      }

      .mark {
        font-size: 12px;
        color: #4684e7;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .list {
      max-height: 575px;
      overflow-y: scroll;
    }
  }
}
