.toggle-read {
  display: flex;
  align-items: center;
}

.mark {
  font-size: 14px;
  font-weight: 600;
  color: #4684e7;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.notification-detail {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  &:hover {
    background-color: #eefafe;
    border-radius: 8px;
    cursor: pointer;
  }
}

.no-data {
  padding: 50px 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.show-list {
  max-height: 575px;
  overflow-y: scroll;
}
