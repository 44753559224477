.kanban-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    .showing {
      font-size: 12px;
      font-weight: 600;
      color: #6d7176;
    }
  }
  .right {
    display: flex;
    align-items: center;
  }
}
