.user-profile-popover {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  .user-profile-button {
    height: 40px;
    width: 40px;
    border-radius: 40px;
  }
  .popover-user {
    width: 266px;
    max-height: 188px;
    position: absolute;
    z-index: 999;
    top: 55px;
    right: 32px;
    background: #fff;
    border-radius: 8px;
    box-shadow:
      2px 2px 14px 0px rgba(67, 71, 72, 0.35),
      0px 0px 2px 0px rgba(0, 0, 0, 0.1);
    .popover-user-container {
      .user-profile {
        padding: 16px 16px 0 16px;
        display: flex;
        gap: 16px;
        .avatar {
          height: 60px;
          width: 60px;
        }
        .information {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          .full-name {
            color: var(--grayscale-800, #2d2f31);
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
          .job-title {
            color: var(--grayscale-600, #6d7176);
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.12px;
          }
        }
      }
      .menu-list {
        height: 40px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        margin: 8px 0;
        width: 100%;
        .menu-item {
          display: flex;
          height: 40px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          padding-left: 16px;
          cursor: pointer;
          p {
            cursor: pointer;
          }
        }
        :hover {
          background: var(--action-hover, #eefafe);
        }
      }
    }
  }
}
