.noti-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 10px 24px;
  cursor: pointer;

  &:hover {
    background-color: #eefafe;
    border-radius: 8px;
  }
  .left {
    max-width: 326px;

    .date {
      font-size: 12px;
    }
  }
}
