@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.cmp-popover {
  > .MuiPopover-paper {
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    min-width: 274px;
  }
  &.is-mini {
    > .MuiPopover-paper {
      min-width: 174px;
    }
  }
}
button.cmp-popover__button {
  min-width: 0;
  padding: 10px 16px;
  text-transform: none;
}
