@use "src/scss/vars" as *;
@use "src/scss/mixin" as *;

.cmp-phoneinput {
  .PhoneInputCountry,
  .PhoneInputInput,
  input {
    border-color: #cfd4d9;
    background-color: #ffffff;
  }
  .PhoneInputCountryIcon,
  input {
    background-color: #ffffff;
  }
  &__input--error {
    .PhoneInputCountry,
    .PhoneInputInput,
    input {
      border-color: $color-danger;
    }
  }

  &__input--error.PhoneInput--focus {
    .PhoneInputCountry,
    .PhoneInputInput,
    input {
      border-color: $color-danger;
      // box-shadow: $error-shadow;
    }
  }
}
